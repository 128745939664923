export default function Footer() {
  return (
    <div>
      <p className="text-center">
        Made with by ❤️ Shivam. <br />
        Inspired by{" "}
        <a href="https://brittanychiang.com/" target="_blank noreferrer">
          Brittany Chiang
        </a>
      </p>
    </div>
  );
}
